import React from 'react'
import { map } from 'ramda'
import { graphql, Link } from 'gatsby'
import { DateTime } from 'luxon'
import styled from 'styled-components'
import Layout from 'components/Layout'
import SEO from '../components/SEO'

const Item = styled(Link)`
  display: block;
  box-shadow: 1px 2px 8px #d4d4d5;
  margin: 0 0 1rem 0;
  padding: 1rem;
  width: 100%;
`
const Title = styled.h2`
  color: #ba2c73;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
`
const Date = styled.div`
  margin-bottom: 0.5rem;
`
const Location = styled.div`
  margin-bottom: 0.5rem;
`

/*eslint-disable-next-line*/
const renderEvents = map(({ node: event }) => (
  <Item key={event.slug} to={`/veranstaltungen/${event.id}`}>
    <Title>{event.name.text}</Title>
    <Date>
      {DateTime.fromISO(event.start.local)
        .setLocale('de')
        .toLocaleString(DateTime.DATETIME_MED)}
    </Date>
    <Location>Julius Klengel Akademie Leipzig</Location>
  </Item>
))

const Component = ({ data }) => {
  const {
    events: { edges: events },
  } = data
  return (
    <Layout>
      <SEO data={{ title: 'Veranstaltungen' }} />
      <h1>Veranstaltungen</h1>
      {renderEvents(events)}
      {/*{events.length <= 0 ? <p>Keine aktuellen Veranstaltungen</p> : null}*/}
    </Layout>
  )
}

export const eventsQuery = graphql`
  query Events {
    events: allEventbriteEvents(filter: { status: { eq: "live" } }) {
      edges {
        node {
          id
          name {
            text
          }
          description {
            html
            text
          }
          start {
            timezone
            local
            utc
          }
        }
      }
    }
  }
`

export default Component
